<template>
  <div class="form-content">
    <el-form ref="form" :model="form">
      <el-form-item :label="$t('Brand')" style="max-width: 80%">
        <el-select v-model="form.brand" :placeholder="$t('Select')">
          <el-option
            v-for="brand in brands"
            :key="brand.id"
            :label="brand.name"
            :value="brand.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('Restaurant Name')" style="max-width: 80%">
        <el-input v-model="form.restaurantName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('Restaurant Logo')" style="max-width: 80%">
        <el-upload
        :action="uploadUrl"
        :file-list="tmpFile"
        :on-preview="onPreview"
        :on-success="onUploaded"
        :on-remove="onRemove"
        :limit="1"
        list-type="picture-card">
        <i class="el-icon-plus"></i>
      </el-upload>
      </el-form-item>
    </el-form>
    <div class="submit-wrapper">
      <el-button class="submit-button" type="primary" @click="submit">{{ $t('Submit') }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Brand from '@/lib/brand';
import Restaurant from '@/lib/restaurant';

export default {
  name: 'RestaurantForm',
  mounted(){
    this.uploadUrl = `${this.apiUrl}/restaurant/file`;
    this.loadBrands();
  },
  methods:{
    async loadBrands(){
      const loginInfo = Common.getLoginInfo();
      const brands = await Brand.loadAllBrands(this.apiUrl, loginInfo);
      this.brands = brands;
    },
    submit(){
      this.$emit('action', this.form);
    },
    onPreview(file){
      window.open(file.url, "_blank");
    },
    onRemove(file){
      this.form.restaurantLogo = '';
    },
    async onUploaded(response, file, fileList){
      const fileUrl = response['image_url'];
      this.form.restaurantLogo = fileUrl;
    },
  },
  data(){
    const form = {
      restaurantLogo: '',
      restaurantName: '',
      brand: '',
    };
    return {
      brands: [],
      tmpFile: [],
      uploadUrl: '',
      form,
    }
  },
  watch: {
    'currentRestaurant': function(){
      if(this.currentRestaurant.logo){
        const imageFileName = this.currentRestaurant.logo.substring(this.currentRestaurant.logo.lastIndexOf('/')+1);
        const imageFile = {
          name: imageFileName,
          url: this.currentRestaurant.logo,
        };
        this.tmpFile.push(imageFile);
      }
      this.form.brand = this.currentRestaurant.related_brand;
      this.form.restaurantLogo = this.currentRestaurant.logo;
      this.form.restaurantName = this.currentRestaurant.name;
    }
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
    currentRestaurant: state => state.brand.currentRestaurant,
  }),
}
</script>

<style lang="scss">
.form-content{
  padding-left: 20px;
  .el-form-item__label{
    float: none;
  }
  .update-button{
    .el-form-item__content{
      text-align: right;
    }
  }
  .submit-wrapper{
    text-align: right;
  }
}
</style>
